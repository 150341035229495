import React from "react";
import loadable from '@loadable/component'

const SingleSignUpCta = loadable(() =>  import("./single-sign-up-cta"));
const SmallContentCtaListing = loadable(() =>  import("./small-content-cta-listing"));
const SingleCtaWithLargeImage = loadable(() =>  import("./single-cta-with-large-image"));
const MediumContentCtaListing = loadable(() =>  import("./medium-content-cta-listing"));

const Start = ({ module }) => {
    const { signUpStepCta } = module;
    const { display } = signUpStepCta;

    switch(display) {
        case "small-content-cta-listing":
            return (<SmallContentCtaListing module={signUpStepCta} />);
        case "single-sign-up-cta":
            return (<SingleSignUpCta module={signUpStepCta} />);
        case "single-cta-with-large-image":
            return (<SingleCtaWithLargeImage module={signUpStepCta} />);
        case "medium-content-cta-listing":
            return (<MediumContentCtaListing module={signUpStepCta} />)
        default:
            return null;
    }
};

export default Start;